import { Component, OnInit } from '@angular/core';
import { CompanyService } from 'src/app/api/services/company.service';
import { FirebaseService } from 'src/app/api/services/firebase.service';
import { MatTableDataSource, MatDialog } from '@angular/material';
import { QrcodeDialogComponent } from 'src/app/dialogs/qrcode-dialog/qrcode-dialog.component';
import { Tag } from 'src/app/interfaces/Tag';
import { TagService } from 'src/app/api/services/tag.service';
import { Router } from '@angular/router';
import { UserService } from 'src/app/api/services/user.service';
import { Company } from 'src/app/interfaces/Company';

@Component({
  selector: 'app-tag-list',
  templateUrl: './tag-list.component.html',
  styleUrls: ['./tag-list.component.scss']
})
export class TagListComponent implements OnInit {

  public tagList_columns: Array<string> = ["ID","DataType","Description","User","View","Edit","Delete"]
  public dataSource;

  constructor(
    private fs: FirebaseService,
    public cs:CompanyService,
    private us:UserService,
    private dialog:MatDialog,
    private tagServ: TagService,
    private router: Router
  ) { }

  ngOnInit() {

    let isUserLogged = localStorage.getItem("tokenid");
    let useremail = localStorage.getItem("useremail");
    if (isUserLogged) {
      this.fs.GetUserData(useremail).then((res) => {

        this.us.User = res;
        this.fs.GetCompany(this.us.User.company).then((cpres) => {
          this.cs.Company = cpres;
          this.fs.GetTagListByCompany(this.cs.Company.id).then((tagList) => {
            this.dataSource = new MatTableDataSource(tagList);
          });
        })
      });
    }

    if (!this.us.gotIt)
      this.router.navigateByUrl('');
  }

  public tagsTable_filter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  public openDialog(selectedTag: Tag): void {
    this.tagServ.Tag = selectedTag;
    const dialogRef = this.dialog.open(QrcodeDialogComponent, {
      width: '350px',
    });
  }

  public deleteTag(selectedTag:Tag) {
    this.fs.DeleteTag(selectedTag.id).then(()=>{
      this.ngOnInit();
    });
  }

  public editTag(selectedTag: Tag) {
    this.tagServ.Tag = selectedTag;
    this.router.navigateByUrl('qr-editor');
  }

}
