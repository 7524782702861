import { Component, OnInit } from '@angular/core';
import { Tag } from 'src/app/interfaces/Tag';
import { MatSnackBar, MatDialog } from '@angular/material';
import { FirebaseService } from 'src/app/api/services/firebase.service';
import { UserService } from 'src/app/api/services/user.service';
import { Company } from 'src/app/interfaces/Company';
import { TagService } from 'src/app/api/services/tag.service';
import { QrcodeDialogComponent } from 'src/app/dialogs/qrcode-dialog/qrcode-dialog.component';
import { VCard } from 'src/app/interfaces/VCard';
import { CompanyService } from 'src/app/api/services/company.service';

interface dataType {
  id: number,
  val: string
}

@Component({
  selector: 'app-create-code',
  templateUrl: './create-code.component.html',
  styleUrls: ['./create-code.component.scss']
})
export class CreateCodeComponent implements OnInit {

  public dataTypes: Array<dataType> = [
    { id: 0, val: "Contatto" },
    { id: 1, val: "Url" }
  ];

  public selectedType: number;
  public selectedCompany: Company = {} as Company;
  public createdTag: Tag = {} as Tag;
  public vcard: VCard = {} as VCard;
  public companiesList: Array<Company> = [];

  constructor(
    private snackbar: MatSnackBar,
    private fs: FirebaseService,
    private us: UserService,
    private dialog: MatDialog,
    private tagServ: TagService,
    private cs: CompanyService
  ) { }

  ngOnInit() {
    this.createdTag.dark = "#000000";
    this.createdTag.light = "#ffffff";
    this.createdTag.transparent = false;

    let isUserLogged = localStorage.getItem("tokenid");
    let useremail = localStorage.getItem("useremail");
    if (isUserLogged) {
      this.fs.GetUserData(useremail).then((res) => {

        this.us.User = res;
        this.fs.GetCompany(this.us.User.company).then((cpres) => {
          this.cs.Company = cpres;
          this.selectedCompany = this.cs.Company;
          if (this.cs.Company.plan == 1) {
            this.fs.GetCustomersList(this.cs.Company.id).then((res) => {
              this.companiesList = res;
              this.companiesList.push(this.cs.Company);
              this.vcard = {} as VCard;
              this.fillVcard();
            });
          } else if (this.cs.Company.plan == 0) {
            this.fs.GetCompaniesList().then((res) => {
              this.companiesList = res;
              this.vcard = {} as VCard;
              this.fillVcard();
            });
          }
        });
      });
    }
  }

  public createTag() {
    
    if (this.selectedType == 0)
      this.setContactTagData();

    if (!this.createdTag.data)
      this.openSnackBar("Compila correttamente i campi", "Ok");
    else {
      if (!this.createdTag.description)
        this.openSnackBar("Inserisci la descrizione del tag", "Ok");
      else {
        if (this.createdTag.transparent == true)
          this.createdTag.light += "00";

        // this.fs.GetCompany(this.us.User.company).then((res) => {
        //   if (this.selectedType == 1)
        //     this.createdTag.data = JSON.stringify(this.createdTag.data);

        //   this.createdTag.company = res;
        //   this.createdTag.user = this.us.User;
        //   this.createdTag.dataType = this.selectedType;

        //   this.fs.CreateTag(this.createdTag).then((res) => {
        //     this.createdTag.id = res;
        //     this.openDialog(this.createdTag);
        //     this.createdTag = {} as Tag;
        //     this.ngOnInit();
        //   }).catch((err) => {
        //     this.openSnackBar(err, "Ok");
        //   });

        // });

        if (this.selectedType == 1)
          this.createdTag.data = JSON.stringify(this.createdTag.data);

        if (this.cs.Company.plan == 2)
          this.createdTag.company = this.cs.Company;
        else
          this.createdTag.company = this.selectedCompany;
        this.createdTag.user = this.us.User;
        this.createdTag.dataType = this.selectedType;

        if (this.selectedCompany.name == "")
          this.createdTag.company = this.cs.Company;

        this.fs.CreateTag(this.createdTag).then((res) => {
          this.createdTag.id = res;
          this.openDialog(this.createdTag);
          this.createdTag = {} as Tag;
          this.ngOnInit();
        }).catch((err) => {
          this.openSnackBar(err, "Ok");
        });
      }
    }
  }

  public openDialog(selectedTag: Tag): void {
    this.tagServ.Tag = selectedTag;
    const dialogRef = this.dialog.open(QrcodeDialogComponent, {
      width: '350px',
    });
  }

  private openSnackBar(message: string, action: string) {
    this.snackbar.open(message, action, {
      duration: 5000,
    });
  }

  private setContactTagData() {
    //set workemail
    if (!this.vcard.workEmailGroup) {
      this.vcard.workEmailGroup = [];
      this.vcard.workEmailGroup.push(this.vcard.workEmail);
      this.vcard.workEmail = '';
    } else {
      if (this.vcard.workEmail) {
        this.vcard.workEmailGroup.push(this.vcard.workEmail);
        this.vcard.workEmail = '';
      }
    }

    //set workphone
    if (!this.vcard.cellPhoneGroup) {
      this.vcard.cellPhoneGroup = [];
      this.vcard.cellPhoneGroup.push(this.vcard.cellPhone);
      this.vcard.cellPhone = '';
    } else {
      if (this.vcard.cellPhone) {
        this.vcard.cellPhoneGroup.push(this.vcard.cellPhone);
        this.vcard.cellPhone = '';
      }
    }

    this.createdTag.data = JSON.stringify(this.vcard);
  }


  private fillVcard() {
    this.vcard = {
      firstName: '',
      lastName: '',
      middleName: '',
      birthday: '' as any,
      homePhone: '',
      cellPhone: '',
      email: '',
      emailGroup: [],
      cellPhoneGroup: [],
      homePhoneGroup: [],
      //work
      organization: '',
      workPhone: '',
      workPhoneGroup: [],
      title: '', //es: software developer
      workUrl: '',
      workFax: '',
      workEmail: '',
      workEmailGroup: [],
      //address
      workAddress: {} as any,
    }
  }

  public addEmailField() {
    if (this.vcard.workEmail) {
      this.vcard.workEmailGroup.push(this.vcard.workEmail);
      this.vcard.workEmail = '';
    }
  }

  public removeEmailField(item) {
    this.vcard.workEmailGroup.splice(this.vcard.workEmailGroup.indexOf(item), 1);
  }

  public addPhoneField() {
    if (this.vcard.cellPhone) {
      this.vcard.cellPhoneGroup.push(this.vcard.cellPhone);
      this.vcard.cellPhone = '';
    }
  }

  public removePhoneField(item) {
    this.vcard.cellPhoneGroup.splice(this.vcard.cellPhoneGroup.indexOf(item), 1);
  }

  public trackByIndex(index: number, obj: any): any {
    return index;
  }

}