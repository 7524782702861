import { Component, OnInit } from '@angular/core';
import { FirebaseService } from './api/services/firebase.service';
import { Router } from '@angular/router';
import { UserService } from './api/services/user.service';
import { CompanyService } from './api/services/company.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = 'tagcloud';
  
  constructor(
    private fs:FirebaseService,
    private router:Router,
    private us: UserService,
    private cs: CompanyService
  ){}

  ngOnInit() {
    let isUserLogged = localStorage.getItem("tokenid");
    let useremail = localStorage.getItem("useremail");
    if (isUserLogged) {
      this.fs.GetUserData(useremail).then((res) => {

        this.us.User = res;
        this.fs.GetCompany(this.us.User.company).then((cpres)=>{
          this.cs.Company = cpres;
          if (this.us.User.level == 0)
            this.router.navigateByUrl("companies");
          else
            // this.router.navigateByUrl("companies/tags");
            this.router.navigateByUrl("tag-list");
          })
      });
    }
    else
      this.router.navigateByUrl("login");
  }

  public resetCs() {
    let isUserLogged = localStorage.getItem("tokenid");
    let useremail = localStorage.getItem("useremail");
    if (isUserLogged) {
      this.fs.GetUserData(useremail).then((res) => {

        this.us.User = res;
        this.fs.GetCompany(this.us.User.company).then((cpres)=>{
          this.cs.Company = cpres;
          })
      });
    }
  }
}
