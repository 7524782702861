import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './views/login/login.component';
import { HomeComponent } from './views/home/home.component';
import { CompaniesComponent } from './views/companies/companies.component';
import { CompaniesTagComponent } from './views/companies-tag/companies-tag.component';
import { UsersComponent } from './views/users/users.component';
import { TrashcanComponent } from './views/trashcan/trashcan.component';
import { CreateCodeComponent } from './views/create-code/create-code.component';
import { EditCodeComponent } from './views/edit-code/edit-code.component';
import { CustomersComponent } from './views/customers/customers.component';
import { TagListComponent } from './views/tag-list/tag-list.component';


const routes: Routes = [
  {path:"login", component: LoginComponent},
  {path:"", component: HomeComponent, children: [
    {path:"companies", component: CompaniesComponent},
    {path:"companies/tags", component: CompaniesTagComponent},
    {path:"users", component: UsersComponent},
    {path:"trashcan", component: TrashcanComponent},
    {path:"qr-generator", component: CreateCodeComponent},
    {path:"qr-editor", component: EditCodeComponent},
    {path:"customers", component: CustomersComponent},
    {path:"tag-list", component: TagListComponent}
  ]},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
