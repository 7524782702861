import { Component, OnInit } from '@angular/core';
import { FirebaseService } from 'src/app/api/services/firebase.service';
import { MatDialogRef, MatSnackBar } from '@angular/material';
import { User } from 'src/app/interfaces/User';

@Component({
  selector: 'app-add-user-dialog',
  templateUrl: './add-user-dialog.component.html',
  styleUrls: ['./add-user-dialog.component.scss']
})
export class AddUserDialogComponent implements OnInit {

  public newUser: User = {} as User;
  public companiesRef: Array<{ ref, name: string }> = [];
  public password: string;

  constructor(
    public dialogRef: MatDialogRef<AddUserDialogComponent>,
    private fs: FirebaseService,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    this.fs.GetCompaniesReference().then((res) => {
      this.companiesRef = res;
    });
  }

  public addUser() {
    this.newUser.level = 1;
    if (!this.newUser.company || !this.newUser.email || !this.password)
      this.openSnackBar("Compila corettamente i campi", "ok");
    else {
      this.fs.CreateUser(this.newUser, this.password).then(() => {
        this.closeDialog();
      });
    }
  }

  public closeDialog() {
    this.dialogRef.close();
  }

  public openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }

}
