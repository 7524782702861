import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginComponent } from './views/login/login.component';
import { MatButtonModule, MatFormFieldModule, MatIconModule, MatInputModule, MatSnackBarModule, MatSidenavModule, MatToolbarModule, MatListModule, MatMenuModule, MatTableModule, MatSelectModule, MatDialogModule, MatCheckboxModule } from '@angular/material';
import { FormsModule } from '@angular/forms';
import { HomeComponent } from './views/home/home.component';
import { HttpClientModule } from '@angular/common/http';
import { CompaniesComponent } from './views/companies/companies.component';
import { CompaniesTagComponent } from './views/companies-tag/companies-tag.component';
import { QRCodeModule } from 'angularx-qrcode';
import { QrcodeDialogComponent } from './dialogs/qrcode-dialog/qrcode-dialog.component';
import { UsersComponent } from './views/users/users.component';
import { TrashcanComponent } from './views/trashcan/trashcan.component';
import { CreateCodeComponent } from './views/create-code/create-code.component';
import { EditCodeComponent } from './views/edit-code/edit-code.component';
import { AddCompanyDialogComponent } from './dialogs/add-company-dialog/add-company-dialog.component';
import { AddUserDialogComponent } from './dialogs/add-user-dialog/add-user-dialog.component';
import { CustomersComponent } from './views/customers/customers.component';
import { AddCustomerDialogComponent } from './dialogs/add-customer-dialog/add-customer-dialog.component';
import { TagListComponent } from './views/tag-list/tag-list.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    CompaniesComponent,
    CompaniesTagComponent,
    QrcodeDialogComponent,
    UsersComponent,
    TrashcanComponent,
    CreateCodeComponent,
    EditCodeComponent,
    AddCompanyDialogComponent,
    AddUserDialogComponent,
    CustomersComponent,
    AddCustomerDialogComponent,
    TagListComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatSnackBarModule,
    MatSidenavModule,
    MatToolbarModule,
    MatListModule,
    MatMenuModule,
    MatTableModule,
    MatSelectModule,
    MatDialogModule,
    QRCodeModule,
    MatCheckboxModule
  ],
  providers: [],
  bootstrap: [AppComponent],
  entryComponents: [
    QrcodeDialogComponent,
    AddCompanyDialogComponent,
    AddUserDialogComponent,
    AddCustomerDialogComponent
  ]
})
export class AppModule { }
