import { Component, OnInit } from '@angular/core';
import { MatDialog, MatTableDataSource } from '@angular/material';
import { AddCustomerDialogComponent } from 'src/app/dialogs/add-customer-dialog/add-customer-dialog.component';
import { Company } from 'src/app/interfaces/Company';
import { CompanyService } from 'src/app/api/services/company.service';
import { FirebaseService } from 'src/app/api/services/firebase.service';
import { Router } from '@angular/router';
import { UserService } from 'src/app/api/services/user.service';

@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.scss']
})
export class CustomersComponent implements OnInit {

  public loaded: boolean = false;
  public companiesTable_colums: Array<string> = ['ID', 'Name', 'Plan', 'Use'];
  public companiesList: Array<Company> = [];
  public dataSource;
  public myId: string = "";

  constructor(
    private dialog: MatDialog,
    private fs: FirebaseService,
    private cs: CompanyService,
    private router: Router,
    private us: UserService
  ) { }

  ngOnInit() {
    let isUserLogged = localStorage.getItem("tokenid");
    let useremail = localStorage.getItem("useremail");
    if (isUserLogged) {
      this.fs.GetUserData(useremail).then((res) => {

        this.us.User = res;
        this.fs.GetCompany(this.us.User.company).then((cpres) => {
          this.cs.Company = cpres;
          this.myId = this.cs.Company.id;
          this.fs.GetCustomersList(this.myId).then((res) => {
            this.companiesList = res;
            this.dataSource = new MatTableDataSource(this.companiesList);
            this.loaded = true;
          });
        })
      });
    }
    
  }

  /**
   * Filter for companies table
   * @param filterValue 
   */
  public companiesTable_filter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  public navigateTo(viewName: string) {
    this.router.navigateByUrl(viewName);
  }

  public selectCompany(selectedRow: any) {
    this.cs.Company = { ...selectedRow };
  }


  public addCompany() {
    const dialog = this.dialog.open(AddCustomerDialogComponent);
    dialog.afterClosed().subscribe(() => {
      this.ngOnInit();
    });
  }
}
