import { Component, OnInit } from '@angular/core';
import { CompanyService } from 'src/app/api/services/company.service';
import { FirebaseService } from 'src/app/api/services/firebase.service';
import { MatTableDataSource, MatDialog } from '@angular/material';
import { QrcodeDialogComponent } from 'src/app/dialogs/qrcode-dialog/qrcode-dialog.component';
import { Tag } from 'src/app/interfaces/Tag';
import { TagService } from 'src/app/api/services/tag.service';
import { Router } from '@angular/router';
import { UserService } from 'src/app/api/services/user.service';
import { Company } from 'src/app/interfaces/Company';

@Component({
  selector: 'app-companies-tag',
  templateUrl: './companies-tag.component.html',
  styleUrls: ['./companies-tag.component.scss']
})
export class CompaniesTagComponent implements OnInit {

  public tagList_columns: Array<string> = ["ID","DataType","Description","User","View","Edit","Delete"]
  public dataSource;

  constructor(
    private fs: FirebaseService,
    public cs:CompanyService,
    private us:UserService,
    private dialog:MatDialog,
    private tagServ: TagService,
    private router: Router
  ) { }

  ngOnInit() {
    if (!this.us.gotIt)
      this.router.navigateByUrl('');
    
      this.fs.GetTagListByCompany(this.cs.Company.id).then((tagList) => {
      this.dataSource = new MatTableDataSource(tagList);
    })
  }

  public tagsTable_filter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  public openDialog(selectedTag: Tag): void {
    this.tagServ.Tag = selectedTag;
    const dialogRef = this.dialog.open(QrcodeDialogComponent, {
      width: '350px',
    });
  }

  public deleteTag(selectedTag:Tag) {
    this.fs.DeleteTag(selectedTag.id).then(()=>{
      this.ngOnInit();
    });
  }

  public editTag(selectedTag: Tag) {
    this.tagServ.Tag = selectedTag;
    this.router.navigateByUrl('qr-editor');
  }

}
