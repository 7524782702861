import { Component, OnInit, ɵNG_ELEMENT_ID } from '@angular/core';
import { TagService } from 'src/app/api/services/tag.service';
import { Tag } from 'src/app/interfaces/Tag';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-qrcode-dialog',
  templateUrl: './qrcode-dialog.component.html',
  styleUrls: ['./qrcode-dialog.component.scss']
})
export class QrcodeDialogComponent implements OnInit {

  public tag: Tag;
  public serverurl: string;
  public image: string = "";
  public svgLink: any;

  constructor(
    private tagServ: TagService,
    private domSanitizer: DomSanitizer,
    public dialogRef: MatDialogRef<QrcodeDialogComponent>
    ) { }

  ngOnInit() {
    this.serverurl = "http://tag.skianet.it"
    // this.serverurl = "http://192.168.1.2:9090"
    this.tag = this.tagServ.Tag;
    this.serverurl += '/tag/?companyid='+ this.tag.company.id +'&tagid=' + this.tag.id;
  }
  
  public downloadPng() {
    var canvas = document.querySelector('canvas') as HTMLCanvasElement;
    this.image = canvas.toDataURL("image/png");
  }

  // public downloadSvg() {
  //   //this.selectedSvg = true;

  //     //var svg = document.getElementById('qrSvg');
  //   var svg = document.querySelector('svg');
  //   var serializer = new XMLSerializer();

  //   var source = serializer.serializeToString(svg);

  //   //add name spaces.
  //   if(!source.match(/^<svg[^>]+xmlns="http\:\/\/www\.w3\.org\/2000\/svg"/)){
  //       source = source.replace(/^<svg/, '<svg xmlns="http://www.w3.org/2000/svg"');
  //   }
  //   if(!source.match(/^<svg[^>]+"http\:\/\/www\.w3\.org\/1999\/xlink"/)){
  //       source = source.replace(/^<svg/, '<svg xmlns:xlink="http://www.w3.org/1999/xlink"');
  //   }

  //   //add xml declaration
  //   source = '<?xml version="1.0" standalone="no"?>\r\n' + source;

  //   //convert svg source to URI data scheme.
  //   var url = this.domSanitizer.bypassSecurityTrustResourceUrl("data:image/svg+xml;charset=utf-8,"+encodeURIComponent(source));

  //   //set url value to a element's href attribute.
  //   this.svgLink = url;
  // }

  public closeDialog() {
    this.dialogRef.close();
  }
}
