import { Component, OnInit } from '@angular/core';
import { FirebaseService } from 'src/app/api/services/firebase.service';
import { MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { UserService } from 'src/app/api/services/user.service';
import { CompanyService } from 'src/app/api/services/company.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public email: string="";
  public password: string="";

  constructor(
    private fs: FirebaseService,
    private snackbar: MatSnackBar,
    private router: Router,
    private us: UserService,
    private cs: CompanyService
    ) { }

  ngOnInit() {
  }

  public login() {
    this.fs.Login(this.email, this.password).then((res) => {
      localStorage.setItem("tokenid",res);
      localStorage.setItem("useremail",this.email);
      this.fs.GetUserData(this.email).then((res)=>{
        this.us.User = res;
        
        if (this.us.User.level == 0)
          this.router.navigateByUrl("companies");
        else {
          this.fs.GetCompany(this.us.User.company).then((company) => {
            this.cs.Company = company;
            this.router.navigateByUrl("tag-list");
          });
        }
      });
    }).catch((err) => {
      this.openSnackBar(err,"Got it");
    });
  }

  public logout() {
    this.fs.Logout().then(()=>{
      localStorage.clear();
    });
  }

  private openSnackBar(message: string, action: string) {
    this.snackbar.open(message, action, {
      duration: 5000,
    });
  }

}
