import { Component, OnInit } from '@angular/core';
import { Company } from 'src/app/interfaces/Company';
import { MatDialogRef } from '@angular/material';
import { FirebaseService } from 'src/app/api/services/firebase.service';
import { CompanyService } from 'src/app/api/services/company.service';

@Component({
  selector: 'app-add-customer-dialog',
  templateUrl: './add-customer-dialog.component.html',
  styleUrls: ['./add-customer-dialog.component.scss']
})
export class AddCustomerDialogComponent implements OnInit {

  public newCompany: Company = {} as Company;
  public plans: Array<{plan:number,label:string}> = [
    {plan: 0, label: "Admin"},
    {plan: 1, label: "Pro (unlimited)"},
    {plan: 2, label: "Standard (max 5 tags)"},
  ];
  
  constructor(
    public dialogRef: MatDialogRef<AddCustomerDialogComponent>,
    private fs: FirebaseService,
    public cs: CompanyService
  ) { }

  ngOnInit() {
  }

  public closeDialog() {
    this.dialogRef.close();
  }

  public addCompany() {
    this.newCompany.use = 0;
    this.newCompany.parent = this.cs.Company.id;
    this.fs.CreateCompany(this.newCompany).then(() => {
      this.closeDialog();
    });
  }

}
