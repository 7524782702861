import { Component, OnInit, ComponentFactoryResolver } from '@angular/core';
import { FirebaseService } from 'src/app/api/services/firebase.service';
import { UserService } from 'src/app/api/services/user.service';
import { Router } from '@angular/router';
import { Company } from 'src/app/interfaces/Company';
import { MatTableDataSource, MatDialog } from '@angular/material';
import { CompanyService } from 'src/app/api/services/company.service';
import { AddCompanyDialogComponent } from 'src/app/dialogs/add-company-dialog/add-company-dialog.component';
import { finalize } from 'rxjs/operators';
import { User } from 'src/app/interfaces/User';

@Component({
  selector: 'app-companies',
  templateUrl: './companies.component.html',
  styleUrls: ['./companies.component.scss']
})
export class CompaniesComponent implements OnInit {

  public loaded: boolean = false;
  public companiesTable_colums: Array<string> = ['ID','Name','Plan','Use'];
  public companiesList: Array<Company> = [];
  public dataSource;

  constructor(
    private fs:FirebaseService,
    private us:UserService,
    private cs:CompanyService,
    private router:Router,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
      if (this.us.User.level != 0) //View enabled only for admin
          this.navigateTo('');
      else {
        this.fs.GetCompaniesList().then((res) => {
          this.companiesList = res;
          this.dataSource = new MatTableDataSource(this.companiesList);
          this.loaded = true;
        });
      }
  }

  /**
   * Filter for companies table
   * @param filterValue 
   */
  public companiesTable_filter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  public navigateTo(viewName: string) {
    this.router.navigateByUrl(viewName);
  }

  public selectCompany(selectedRow: any) {
    this.cs.Company = {...selectedRow};
  }

  public addCompany() {
    const dialog = this.dialog.open(AddCompanyDialogComponent);
    dialog.afterClosed().subscribe(() => {
      this.ngOnInit();
    });
  }

}
