import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/api/services/user.service';
import { FirebaseService } from 'src/app/api/services/firebase.service';
import { Company } from 'src/app/interfaces/Company';
import { Tag } from 'src/app/interfaces/Tag';
import { MatTableDataSource, MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';

@Component({
  selector: 'app-trashcan',
  templateUrl: './trashcan.component.html',
  styleUrls: ['./trashcan.component.scss']
})
export class TrashcanComponent implements OnInit {

  public isAdmin: boolean;
  public companiesList: Array<Company> = [];
  public companyTrash: Company = {} as Company;
  public trashTag: Array<Tag> = [];
  public dataSource;
  public trashList_columns: Array<string> = ["ID", "DataType", "Description", "User", "Remove", "Restore"];

  constructor(
    private us: UserService,
    private fs: FirebaseService,
    private router: Router,
    private snackbar: MatSnackBar
  ) { }

  ngOnInit() {
    this.isAdmin = false;

    if (!this.us.gotIt)
      this.router.navigateByUrl("");

    this.fs.GetCompany(this.us.User.company).then((res) => {
      this.companyTrash = res;
      // console.log(this.companyTrash)
      if (this.us.User.level == 0) {
        this.isAdmin = true;
        this.fs.GetCompaniesList().then((res) => {
          this.companiesList = res;
        });
      }
      else {
        this.getTrashcan(this.companyTrash);
      }
    });
  }

  public getTrashcan(trashcompany: Company) {
    this.fs.GetTrashcan(trashcompany.id).then((res) => {
      this.trashTag = res;
      this.dataSource = new MatTableDataSource(this.trashTag);
      // console.log(this.trashTag);
    });
  }

  public deleteTagForever(selectedTag: Tag) {
    this.fs.DeleteTagForever(selectedTag).then(() => {
      this.ngOnInit();
      this.getTrashcan(this.companyTrash);
    })
  }

  public restoreTag(selectedTag: Tag) {
    this.fs.RestoreTag(selectedTag).then(() => {
      this.ngOnInit();
      this.getTrashcan(this.companyTrash);
    }).catch((err) => {
      this.openSnackBar(err, "Ok");
    });
  }

  private openSnackBar(message: string, action: string) {
    this.snackbar.open(message, action, {
      duration: 5000,
    });
  }

}
