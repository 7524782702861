import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { Company } from 'src/app/interfaces/Company';
import { FirebaseService } from 'src/app/api/services/firebase.service';

@Component({
  selector: 'app-add-company-dialog',
  templateUrl: './add-company-dialog.component.html',
  styleUrls: ['./add-company-dialog.component.scss']
})
export class AddCompanyDialogComponent implements OnInit {

  public newCompany: Company = {} as Company;
  public plans: Array<{plan:number,label:string}> = [
    {plan: 0, label: "Admin"},
    {plan: 1, label: "Pro (unlimited)"},
    {plan: 2, label: "Standard (max 5 tags)"},
  ];

  constructor(
    public dialogRef: MatDialogRef<AddCompanyDialogComponent>,
    private fs: FirebaseService
  ) { }

  ngOnInit() {
  }

  public closeDialog() {
    this.dialogRef.close();
  }

  public addCompany() {
    this.newCompany.use = 0;
    this.newCompany.parent = "none";
    this.fs.CreateCompany(this.newCompany).then(() => {
      this.closeDialog();
    });
  }

}
